import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
// import Debug from "../components/Debug"

const agendaData = [
  {
    time: "time one",
    events: [
      {
        title: "Sample Event Name",
        speaker: "Stu Finn",
        affiliation: "Borealis Web Development",
      },
      { title: "Another Event Name" },
      { title: "three" },
    ],
  },
  {
    time: "time two",
    events: [{ title: "a" }, { title: "b" }, { title: "c" }],
  },
  {
    time: "time three",
    events: [
      {
        title: (
          <span className="font-bold">
            This should span
            <br />
            Next line
          </span>
        ),
      },
    ],
  },
  {
    time: "time two",
    events: [{ title: "a" }, { title: "b" }, { title: "" }],
  },
]

const GridDay = ({ children }) => (
  <div className="grid w-3/4 grid-cols-agenda-grid col-gap-2 max-w-3/4 row-gap-2 my-5">
    {children}
  </div>
)

const GridTime = ({ children, color = "bg-nofnecGreen" }) => (
  <div
    className={`font-bold flex justify-center items-center ${color} p-2 text-white`}
  >
    {children}
  </div>
)

const GridGroup = ({ children }) => (
  <div className="grid grid-cols-3  col-gap-2">{children}</div>
)

const GridEvent = ({ children, color }) => (
  <div
    className={`bg-${color} grid justify-center p-3 border border-black text-center`}
  >
    {children}
  </div>
)

const GridSpan = ({ children }) => (
  <div className="bg-orange-200 flex justify-center items-center col-span-3 border border-black">
    {children}
  </div>
)

const GridRoom = ({ children, color }) => (
  <GridEvent color={color}>
    <span className="text-white text-center">{children}</span>
  </GridEvent>
)

const Agenda = () => {
  return (
    <Layout>
      <SEO title="Agenda" />
      <div className="py-10 flex flex-col items-center ">
        <h1 className="text-6xl">Agenda</h1>

        <GridDay>
          {/* Room Row */}
          <GridTime color="" />
          <GridGroup>
            <GridRoom color="blue-700">
              Waste Management
              <br />
              Amethyst Room
            </GridRoom>
            <GridRoom color="green-700">
              Environmental Sustainability
              <br />
              Kaministiquia Room
            </GridRoom>
            <GridRoom color="red-700">
              Engaging Opportunities
              <br /> Point Du Meuron Room
            </GridRoom>
          </GridGroup>
          {/* End of Room Row */}

          {agendaData.map(agendaItem => {
            return (
              <>
                <GridTime>{agendaItem.time}</GridTime>
                <GridGroup>
                  {/* if there is only one event entry, it should span the column */}
                  {agendaItem.events.length === 1 ? (
                    <GridSpan>{agendaItem.events[0].title}</GridSpan>
                  ) : (
                    agendaItem.events.map((event, index) => {
                      {
                        /* Assign colors to columns */
                      }
                      let color = "white"
                      if (index === 0) {
                        color = "blue-300"
                      } else if (index === 1) {
                        color = "green-300"
                      } else if (index === 2) {
                        color = "red-300"
                      }

                      return (
                        <GridEvent color={color}>
                          <div className="grid">
                            <span className="font-bold ">{event.title}</span>
                            {event.speaker && <span>{event.speaker}</span>}
                            {event.affiliation && (
                              <span className="italic">
                                {event.affiliation}
                              </span>
                            )}
                          </div>
                        </GridEvent>
                      )
                    })
                  )}
                </GridGroup>
              </>
            )
          })}
        </GridDay>
      </div>
    </Layout>
  )
}

export default Agenda
